.alert-container-right {
  position: fixed;
  width: 100%;
  right: 0;
  top: 80px;
  z-index: 100;
  &.fade-in {
    opacity: 1;
    animation: fadeIn ease .5s;
    -webkit-animation: fadeIn ease .5s;
    -moz-animation: fadeIn ease .5s;
    -o-animation: fadeIn ease .5s;
    -ms-animation: fadeIn ease .5s;
  }
  .alert-onboarding {
    color: $primary;
    .alert-close {
      color: $grey;
    }
    .alert-copy {
      width: 100%;
      color: $primary !important;
    }
    @media (max-width: 576px) {
      .alert-copy {
        width: 90%;
      }
    }
  }

}
.kw-settings { font-size: .75rem; }

.kwt-download { left: 0px !important; }

.tool-sub-header { 
  font-size:  0.9rem; 
  @media (max-width: 576px ) {
    font-size: 0.8rem !important;
  }
}
.kwt-edit-icon {
  color: #4f4f4f;
}
.append-header { cursor: pointer; }

.keyword-object {
  width: auto;
  text-transform: unset !important;
  margin: 6px;
}

.category-container {
  display: flex;
  @media(max-width: 766px) {
    display: block;
  }
}

.keyword-list {
  padding-left: 0rem !important;
  margin-bottom: 0rem;
  list-style: none;
}

.keyword-panel {
  margin-left: 300px;
  margin-right: 300px;
  // margin-bottom: 1rem;
}

.keyword-container {
  border-radius: 7px;
  padding: 12px;
}

.keyword-card {
  cursor: pointer;
}

.keyword-value {
  float: left;
}

.msv-text {
  // padding: 20px 0px 0px 0px;
  font-size: .8rem !important;
  word-wrap: break-word !important;
  // text-align: left !important;
}

.msv-value {
  float: right;
}

#keyword-export {
  position: relative;
  top: 8px;
  // margin-right: 9px;
}

.warning-container{
  position: absolute !important;
  top: 0px !important;
  left: 30%;
  width: 40% !important;

}

.warning-container-fixed {
  top: 100px !important;
  position: fixed !important;
  left: 30%;
  width: 40% !important;
}

.relative-alert-panel {
  position: relative;
}

.temp-slider {
  width: 300px;
  margin-top: 5px;
  margin-bottom: 5px;
}

input[type=range]{
  appearance: none;
  -webkit-appearance: none;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    background: #f2ddf9;
    margin-top: -7px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

input[type=range]::-webkit-slider-runnable-track {
    height: 6px;
    border-width: 0px;
    border-radius: 7px;
    background: #000;
}

input[type=range]:focus::-webkit-slider-thumb {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background: #f2ddf9;
}

input[type=range]:active::-webkit-slider-thumb {
      background: rgb(216, 202, 221);
}

.temp-input-group {
  width: 20%;
}

.temp-input { width: 60px; }

.kw-tab {
  @media (max-width: 404px ) {
    font-size: 14px !important;
}
  @media (max-width: 367px ) {
      font-size: 12px !important;
  }

}