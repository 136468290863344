.monthly-annual-toggle-plans-container {
	padding: 1.5rem;
	background-color: transparent;
}

.selectedPlanCard {
	border: 2px solid;
}

.twitter-tweet {
	display: inline-block !important;
	width: auto !important;
}