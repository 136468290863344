// Type
.note {
  padding: 10px;
  border-left: 6px solid;
  border-radius: 5px;
  strong {
    font-weight: 600;
  }
  p {
    font-weight: 500;
  }
}

@each $name, $color in $note {
  .note-#{$name} {
    background-color: map-get($color, bgc);
    border-color: map-get($color, border-color);
  }
}
