.form-check-input:checked {
	border-color: $primary;
}

.form-check-input[type=radio]:checked:after {
	border-color: $primary;
	background-color: $primary;
}

.form-check-input:checked:focus {
	border-color: $primary;
}

.form-check-input {
	background-color: $background1;
}

.form-check-input[type='checkbox']:checked {
	background-color: $primary;
}

.form-check-input[type='checkbox']:focus:after {
	background-color: transparent;
}

.form-check-input[type='checkbox']:checked:focus {
	background-color: $primary;
}

.form-check-input:checked:focus:before {
	box-shadow: 0px 0px 0px 13px transparent;
}

.input-group > .form-control:focus {
	border-color: $primary;
	box-shadow: inset 0 0 0 1px $primary;
}

.form-control:focus {
	border-color: $primary;
	box-shadow: inset 0 0 0 1px $primary;
}

a {
	&.link {
		color: $link-color;
		&:hover {
			text-decoration: underline;
		}
	}
	&.underline {
		background-repeat: repeat-x;
    background-image: linear-gradient(to right,currentColor 100%,currentColor 0);
    background-size: 1px 1px;
    background-position: 0 100%;
    white-space: initial;
	}
}

input {
	&.error {
		border-color: $error-color;
	}
}

.error:not(input) {
	color: $error-color;
	font-size: .75rem;
}

.input-group {
	label.error {
		width: 100%;
	}
}

input:not(.error) ~ label.error {
	display: none;
}

.custom-option {
	display: flex;
  align-items: center;
	padding: .5rem;
	cursor: pointer;
	&:hover {
		background: $light-blue;
	}
}

i {
	cursor: pointer;
}

.google-serp-export {
	box-shadow: none;
	&.btn.disabled {
		box-shadow: none;
	}
	&.btn:hover {
		box-shadow: none;
	}
	&.btn:active {
		box-shadow: none;
	}
}

.input-notes {
	color: $grey;
	font-size: .75rem;
}

.toggle-switch-container {
	.form-check-input[type='checkbox']:focus:after {
		background-color: $background1;
		border-radius: 50%;
		width: 1.25rem;
		height: 1.25rem;
	}

	.form-switch .form-check-input {
		background-color: $primary;
	}
	.form-switch .form-check-input:checked[type='checkbox']:after {
		background-color: $background1;
	}
	.form-check-input[type='checkbox']:checked {
		background-color: $primary;
	}
	.form-check-input[type='checkbox']:checked:focus {
		background-color: $primary;
	}
	.form-switch .form-check-input:after {
		background-color: $primary;
	}
	.form-switch .form-check-input:focus:before {
		box-shadow: none;
	}
	.form-switch .form-check-input:after {
		background-color: $background1;
	}
}

@media (max-width: 576px) {
	.flex-horizontal-center-sm {
		display: flex;
		justify-content: center;
		.form-switch {
			flex-grow: 0 !important;
		}
	}
}
