//
// Material styles for form control - form outline
//

.form-outline {
  position: relative;
  .form-control {
    min-height: auto;
    padding-top: 0.33rem;
    padding-bottom: 0.33rem;
    border: 0;
    background: transparent;
    transition: all 0.2s linear;
    ~ .form-label {
      position: absolute;
      top: 0;
      left: 0.75rem;
      padding-top: 0.37rem;
      pointer-events: none;
      transform-origin: 0 0;
      transition: all 0.2s ease-out;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 0;
    }
    ~ .form-notch {
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      max-width: 100%;
      height: 100%;
      text-align: left;
      pointer-events: none;
      div {
        pointer-events: none;
        border: 1px solid;
        border-color: #bdbdbd;
        box-sizing: border-box;
        background: transparent;
      }
      .form-notch-leading {
        left: 0;
        top: 0;
        height: 100%;
        width: 8px;
        border-right: none;
        border-radius: 4px 0 0 4px;
      }
      .form-notch-middle {
        flex: 0 0 auto;
        width: auto;
        max-width: calc(100% - 16px);
        height: 100%;
        border-right: none;
        border-left: none;
      }
      .form-notch-trailing {
        flex-grow: 1;
        height: 100%;
        border-left: none;
        border-radius: 0 4px 4px 0;
      }
    }
    &:focus,
    &.active {
      &::placeholder {
        opacity: 1;
      }
    }
    &:focus {
      box-shadow: none !important;
    }
    &:focus ~ .form-label,
    &.active ~ .form-label {
      transform: translateY(-1rem) translateY(0.1rem) scale(0.8);
    }
    &:focus ~ .form-label {
      color: $primary;
    }
    &:focus ~ .form-notch .form-notch-middle,
    &.active ~ .form-notch .form-notch-middle {
      border-top: none;
      border-right: none;
      border-left: none;
    }
    &:focus ~ .form-notch .form-notch-middle {
      border-bottom: 2px solid;
      border-color: $primary;
      transition: all 0.2s linear;
    }
    &:focus ~ .form-notch .form-notch-leading,
    &.active ~ .form-notch .form-notch-leading {
      border-right: none;
    }
    &:focus ~ .form-notch .form-notch-leading {
      border-top: 2px solid $primary;
      border-bottom: 2px solid $primary;
      border-left: 2px solid $primary;
      transition: all 0.2s linear;
    }
    &:focus ~ .form-notch .form-notch-trailing,
    &.active ~ .form-notch .form-notch-trailing {
      border-left: none;
    }
    &:focus ~ .form-notch .form-notch-trailing {
      border-top: 2px solid;
      border-bottom: 2px solid;
      border-right: 2px solid;
      border-color: $primary;
      transition: all 0.2s linear;
    }
    &:disabled,
    &.disabled,
    &[readonly] {
      background-color: #e9ecef;
    }
    &::placeholder {
      opacity: 0;
    }
    &.form-control-lg {
      font-size: 1rem;
      line-height: 2.15;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      ~ .form-label {
        padding-top: 0.7rem;
      }
      &:focus ~ .form-label,
      &.active ~ .form-label {
        transform: translateY(-1.25rem) translateY(0.1rem) scale(0.8);
      }
    }
    &.form-control-sm {
      font-size: 0.775rem;
      line-height: 1.5;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      ~ .form-label {
        padding-top: 0.33rem;
        font-size: 0.775rem;
      }
      &:focus ~ .form-label,
      &.active ~ .form-label {
        transform: translateY(-0.83rem) translateY(0.1rem) scale(0.8);
      }
    }
  }

  &.form-white {
    .form-control {
      color: #fff;
      ~ .form-label {
        color: #fbfbfb;
      }
      ~ .form-notch {
        div {
          border-color: #fbfbfb;
        }
      }
      &:focus ~ .form-label {
        color: #fff;
      }
      &:focus ~ .form-notch .form-notch-middle {
        border-color: #fff;
      }
      &:focus ~ .form-notch .form-notch-leading {
        border-top: 2px solid #fff;
        border-bottom: 2px solid #fff;
        border-left: 2px solid #fff;
      }
      &:focus ~ .form-notch .form-notch-trailing {
        border-color: #fff;
      }
    }
  }
}
