.account-gate-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color:rgba($primary,0);
  overflow-y: scroll;
  &.fade-in {
    display: block;
    transition: opacity 0.15s linear;
    background-color:rgba($primary,0.5);
  }
  .account-gate-modal-container {
    background-color: $background1;
  }
  .sign-in-account-flex {
    align-items: center;
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
      .create-account-form {
        width: 45%;
      }
      .sign-in-form {
        width: 50%;
      }
    }
  }
}