// Variables

// Color system

// Grays

$white: #fff;
$gray-50: #fbfbfb;
$gray-100: #f5f5f5;
$gray-200: #eeeeee;
$gray-300: #e0e0e0;
$gray-400: #bdbdbd;
$gray-500: #9e9e9e;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #4f4f4f;
$gray-900: #262626;
$black: #000;

// fusv-disable
$blue-50: #e3f2fd;
$blue-100: #bbdefb;
$blue-200: #90caf9;
$blue-300: #64b5f6;
$blue-400: #42a5f5;
$blue-500: #2196f3;
$blue-600: #1e88e5;
$blue-700: #1976d2;
$blue-800: #1565c0;
$blue-900: #0d47a1;
$blue-accent-100: #82b1ff;
$blue-accent-200: #448aff;
$blue-accent-400: #2979ff;
$blue-accent-700: #2962ff;

$indigo-50: #e8eaf6;
$indigo-100: #c5cae9;
$indigo-200: #9fa8da;
$indigo-300: #7986cb;
$indigo-400: #5c6bc0;
$indigo-500: #3f51b5;
$indigo-600: #3949ab;
$indigo-700: #303f9f;
$indigo-800: #283593;
$indigo-900: #1a237e;
$indigo-accent-100: #8c9eff;
$indigo-accent-200: #536dfe;
$indigo-accent-400: #3d5afe;
$indigo-accent-700: #304ffe;

$purple-50: #f3e5f5;
$purple-100: #e1bee7;
$purple-200: #ce93d8;
$purple-300: #ba68c8;
$purple-400: #ab47bc;
$purple-500: #9c27b0;
$purple-600: #8e24aa;
$purple-700: #7b1fa2;
$purple-800: #6a1b9a;
$purple-900: #4a148c;
$purple-accent-100: #ea80fc;
$purple-accent-200: #e040fb;
$purple-accent-400: #d500f9;
$purple-accent-700: #aa00ff;

$pink-50: #fce4ec;
$pink-100: #f8bbd0;
$pink-200: #f48fb1;
$pink-300: #f06292;
$pink-400: #ec407a;
$pink-500: #e91e63;
$pink-600: #d81b60;
$pink-700: #c2185b;
$pink-800: #ad1457;
$pink-900: #880e4f;
$pink-accent-100: #ff80ab;
$pink-accent-200: #ff4081;
$pink-accent-400: #f50057;
$pink-accent-700: #c51162;

$red-50: #ffebee;
$red-100: #ffcdd2;
$red-200: #ef9a9a;
$red-300: #e57373;
$red-400: #ef5350;
$red-500: #f44336;
$red-600: #e53935;
$red-700: #d32f2f;
$red-800: #c62828;
$red-900: #b71c1c;
$red-accent-100: #ff8a80;
$red-accent-200: #ff5252;
$red-accent-400: #ff1744;
$red-accent-700: #d50000;

$orange-50: #fff3e0;
$orange-100: #ffe0b2;
$orange-200: #ffcc80;
$orange-300: #ffb74d;
$orange-400: #ffa726;
$orange-500: #ff9800;
$orange-600: #fb8c00;
$orange-700: #f57c00;
$orange-800: #ef6c00;
$orange-900: #e65100;
$orange-accent-100: #ffd180;
$orange-accent-200: #ffab40;
$orange-accent-400: #ff9100;
$orange-accent-700: #ff6d00;

$yellow-50: #fffde7;
$yellow-100: #fff9c4;
$yellow-200: #fff59d;
$yellow-300: #fff176;
$yellow-400: #ffee58;
$yellow-500: #ffeb3b;
$yellow-600: #fdd835;
$yellow-700: #fbc02d;
$yellow-800: #f9a825;
$yellow-900: #f57f17;
$yellow-accent-100: #ffff8d;
$yellow-accent-200: #ffff00;
$yellow-accent-400: #ffea00;
$yellow-accent-700: #ffd600;

$green-50: #e8f5e9;
$green-100: #c8e6c9;
$green-200: #a5d6a7;
$green-300: #81c784;
$green-400: #66bb6a;
$green-500: #4caf50;
$green-600: #43a047;
$green-700: #388e3c;
$green-800: #2e7d32;
$green-900: #1b5e20;
$green-accent-100: #b9f6ca;
$green-accent-200: #69f0ae;
$green-accent-400: #00e676;
$green-accent-700: #00c853;

$teal-50: #e0f2f1;
$teal-100: #b2dfdb;
$teal-200: #80cbc4;
$teal-300: #4db6ac;
$teal-400: #26a69a;
$teal-500: #009688;
$teal-600: #00897b;
$teal-700: #00796b;
$teal-800: #00695c;
$teal-900: #004d40;
$teal-accent-100: #a7ffeb;
$teal-accent-200: #64ffda;
$teal-accent-400: #1de9b6;
$teal-accent-700: #00bfa5;

$cyan-50: #e0f7fa;
$cyan-100: #b2ebf2;
$cyan-200: #80deea;
$cyan-300: #4dd0e1;
$cyan-400: #26c6da;
$cyan-500: #00bcd4;
$cyan-600: #00acc1;
$cyan-700: #0097a7;
$cyan-800: #00838f;
$cyan-900: #006064;
$cyan-accent-100: #84ffff;
$cyan-accent-200: #18ffff;
$cyan-accent-400: #00e5ff;
$cyan-accent-700: #00b8d4;

$deep-purple-50: #ede7f6;
$deep-purple-100: #d1c4e9;
$deep-purple-200: #b39ddb;
$deep-purple-300: #9575cd;
$deep-purple-400: #7e57c2;
$deep-purple-500: #673ab7;
$deep-purple-600: #5e35b1;
$deep-purple-700: #512da8;
$deep-purple-800: #4527a0;
$deep-purple-900: #311b92;
$deep-purple-accent-100: #b388ff;
$deep-purple-accent-200: #7c4dff;
$deep-purple-accent-400: #651fff;
$deep-purple-accent-700: #6200ea;

$light-blue-50: #e1f5fe;
$light-blue-100: #b3e5fc;
$light-blue-200: #81d4fa;
$light-blue-300: #4fc3f7;
$light-blue-400: #29b6f6;
$light-blue-500: #03a9f4;
$light-blue-600: #039be5;
$light-blue-700: #0288d1;
$light-blue-800: #0277bd;
$light-blue-900: #01579b;
$light-blue-accent-100: #80d8ff;
$light-blue-accent-200: #40c4ff;
$light-blue-accent-400: #00b0ff;
$light-blue-accent-700: #0091ea;

$light-green-50: #f1f8e9;
$light-green-100: #dcedc8;
$light-green-200: #c5e1a5;
$light-green-300: #aed581;
$light-green-400: #9ccc65;
$light-green-500: #8bc34a;
$light-green-600: #7cb342;
$light-green-700: #689f38;
$light-green-800: #558b2f;
$light-green-900: #33691e;
$light-green-accent-100: #ccff90;
$light-green-accent-200: #b2ff59;
$light-green-accent-400: #76ff03;
$light-green-accent-700: #64dd17;

$lime-50: #f9fbe7;
$lime-100: #f0f4c3;
$lime-200: #e6ee9c;
$lime-300: #dce775;
$lime-400: #d4e157;
$lime-500: #cddc39;
$lime-600: #c0ca33;
$lime-700: #afb42b;
$lime-800: #9e9d24;
$lime-900: #827717;
$lime-accent-100: #f4ff81;
$lime-accent-200: #eeff41;
$lime-accent-400: #c6ff00;
$lime-accent-700: #aeea00;

$amber-50: #fff8e1;
$amber-100: #ffecb3;
$amber-200: #ffe082;
$amber-300: #ffd54f;
$amber-400: #ffca28;
$amber-500: #ffc107;
$amber-600: #ffb300;
$amber-700: #ffa000;
$amber-800: #ff8f00;
$amber-900: #ff6f00;
$amber-accent-100: #ffe57f;
$amber-accent-200: #ffd740;
$amber-accent-400: #ffc400;
$amber-accent-700: #ffab00;

$deep-orange-50: #fbe9e7;
$deep-orange-100: #ffccbc;
$deep-orange-200: #ffab91;
$deep-orange-300: #ff8a65;
$deep-orange-400: #ff7043;
$deep-orange-500: #ff5722;
$deep-orange-600: #f4511e;
$deep-orange-700: #e64a19;
$deep-orange-800: #d84315;
$deep-orange-900: #bf360c;
$deep-orange-accent-100: #ff9e80;
$deep-orange-accent-200: #ff6e40;
$deep-orange-accent-400: #ff3d00;
$deep-orange-accent-700: #dd2c00;

$brown-50: #efebe9;
$brown-100: #d7ccc8;
$brown-200: #bcaaa4;
$brown-300: #a1887f;
$brown-400: #8d6e63;
$brown-500: #795548;
$brown-600: #6d4c41;
$brown-700: #5d4037;
$brown-800: #4e342e;
$brown-900: #3e2723;

$blue-gray-50: #eceff1;
$blue-gray-100: #cfd8dc;
$blue-gray-200: #b0bec5;
$blue-gray-300: #90a4ae;
$blue-gray-400: #78909c;
$blue-gray-500: #607d8b;
$blue-gray-600: #546e7a;
$blue-gray-700: #455a64;
$blue-gray-800: #37474f;
$blue-gray-900: #263238;
// fusv-enable

// Theme colors

$primary: #1266f1;
$secondary: #b23cfd;
$success: #00b74a;
$info: #39c0ed;
$warning: #ffa900;
$danger: #f93154;
$light: $gray-50;
$dark: $gray-900;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'white': $white,
  'black': $black,
);

$note: () !default;
$note: map-merge(
  (
    'primary': (
      'bgc': lighten($primary, 43%),
      'border-color': $primary,
    ),
    'secondary': (
      'bgc': lighten($secondary, 33%),
      'border-color': $secondary,
    ),
    'success': (
      'bgc': lighten($success, 53%),
      'border-color': $success,
    ),
    'danger': (
      'bgc': lighten($danger, 36%),
      'border-color': $danger,
    ),
    'warning': (
      'bgc': lighten($warning, 42%),
      'border-color': $warning,
    ),
    'info': (
      'bgc': lighten($info, 36%),
      'border-color': $info,
    ),
    'light': (
      'bgc': $light,
      'border-color': $dark,
    ),
  ),
  $note
);

$body-color: $gray-800;

// Components

$box-shadow-0: none;
$box-shadow-1: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
$box-shadow-2: 0 5px 15px 0 rgba(0, 0, 0, 0.05);
$box-shadow-3: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
$box-shadow-4: 0 15px 30px 0 rgba(0, 0, 0, 0.05);
$box-shadow-5: 0 20px 40px 0 rgba(0, 0, 0, 0.05);

$box-shadow-1-strong: 0px 2px 5px 0px rgba(0, 0, 0, 0.25), 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
$box-shadow-2-strong: 0px 4px 8px 0px rgba(0, 0, 0, 0.25), 0px 5px 15px 2px rgba(0, 0, 0, 0.2);
$box-shadow-3-strong: 0px 6px 11px 0px rgba(0, 0, 0, 0.25), 0px 7px 20px 3px rgba(0, 0, 0, 0.2);
$box-shadow-4-strong: 0px 6px 14px 0px rgba(0, 0, 0, 0.25), 0px 10px 30px 4px rgba(0, 0, 0, 0.2);
$box-shadow-5-strong: 0px 6px 20px 0px rgba(0, 0, 0, 0.25), 0px 12px 40px 5px rgba(0, 0, 0, 0.2);

$shadows-light: (
  '0': $box-shadow-0,
  '1': $box-shadow-1,
  '2': $box-shadow-2,
  '3': $box-shadow-3,
  '4': $box-shadow-4,
  '5': $box-shadow-5,
);

$shadows-strong: (
  '1': $box-shadow-1-strong,
  '2': $box-shadow-2-strong,
  '3': $box-shadow-3-strong,
  '4': $box-shadow-4-strong,
  '5': $box-shadow-5-strong,
);

// Typography

$font-family-roboto: 'Roboto', sans-serif;
$font-family-base: var(--mdb-font-roboto);

$line-height-base: 1.6;

// Badge

$badge-dot-border-radius: 4.5px;
$badge-dot-height: 9px;
$badge-dot-width: 9px;
$badge-dot-ml: -3px;
$badge-notification-mt: -5px;
$badge-notification-ml: -5px;

// Buttons

$btn-line-height: 1.5;
$btn-padding-top: 10px;
$btn-padding-bottom: 8px;
$btn-font-size: 12px;
$btn-padding-left: 24px;
$btn-padding-right: 24px;
$btn-font-weight: 500;
$btn-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
$btn-hover-box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.1);
$btn-focus-box-shadow: $btn-hover-box-shadow;
$btn-active-box-shadow: $btn-hover-box-shadow;
$btn-border-width: 2px;

$btn-outline-padding-top: 8px;
$btn-outline-padding-bottom: 6px;
$btn-outline-padding-left: 22px;
$btn-outline-padding-right: 22px;

$btn-outline-padding-top-sm: 4px;
$btn-outline-padding-bottom-sm: 3px;
$btn-outline-padding-right-sm: 14px;
$btn-outline-padding-left-sm: 14px;

$btn-outline-padding-top-lg: 10px;
$btn-outline-padding-bottom-lg: 9px;
$btn-outline-padding-right-lg: 25px;
$btn-outline-padding-left-lg: 25px;

$btn-line-height-lg: 1.6;
$btn-font-size-lg: 14px;
$btn-padding-top-lg: 12px;
$btn-padding-bottom-lg: 11px;
$btn-padding-right-lg: 27px;
$btn-padding-left-lg: 27px;

$btn-line-height-sm: $btn-line-height;
$btn-font-size-sm: 12px;
$btn-padding-top-sm: 6px;
$btn-padding-bottom-sm: 5px;
$btn-padding-right-sm: 16px;
$btn-padding-left-sm: 16px;

$btn-link-bgc: #f5f5f5;
$btn-rounded-border-radius: 10rem;

// Button group

$btn-group-border-radius: calc(0.25rem - 1px);
$btn-group-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

// Dropdown

$dropdown-menu-animated-animation-duration: 0.55s;
$dropdown-menu-animated-animation-timing-function: ease;
