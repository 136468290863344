// Breadcrumb

.navbar {
  .breadcrumb {
    background-color: transparent;
    margin-bottom: 0;

    .breadcrumb-item {
      a {
        color: rgba(0, 0, 0, 0.55);
        transition: color 0.15s ease-in-out;
        &:hover,
        &:focus {
          color: rgba(0, 0, 0, 0.7);
        }
      }
      + .breadcrumb-item {
        &:before {
          color: rgba(0, 0, 0, 0.55);
        }
      }
    }
  }
}
