.toggle-sample-size-container,
.toggle-view-all-metrics-container {
  flex-direction: column;
  .toggle-switch-container {
    padding-bottom: 1rem;
  }
  @media (min-width: 576px) {
    flex-direction: row;
    .toggle-switch-container {
      padding-bottom: 0;
      padding-right: 3rem;
    }
    
  }
}

.progress {
  &.speed-test-progress-bar {
    width: 27%;
  }
}

@media (min-width: 576px) {
  .progress {
    &.speed-test-progress-bar {
      margin-left: 1rem;
      width: 50%;
    }
  }
}

