.rankings-modal {
  // pointer-events: none;
  cursor: default;
  display: none;
  position: fixed;
  top: 70px;
  left: 0;
  z-index: 999;
  width: 500vw;
  height: 300vh;
  background-color:rgba($primary,0);
  overflow-y: scroll;
  overflow: hidden;
  &.fade-in {
    display: block;
    transition: opacity 0.15s linear;
    background-color:rgba($primary,0.5);
  }
}
.rankings-modal-container {
  background-color: $background1;
  border: 1px solid #000;
}

.position-title {
  margin-top: 12px;
  margin-right: 12px;

}

.card-bd { padding: 30px; }
.checker-mg {
  margin-left: 24px;
  @media (max-width: 576px) {
    margin-left: 8px;
  }
}

.rank-holder {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 30px;
  border: 1px solid #000;
  line-height: 55px;
  text-align: center;
}
.rank-position {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  // margin: 5px 7px 5px 5px;
  margin: 5px 5px 2px 5px;
}
.serp-card-rank-holder {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 18px;
  border: 1px solid #000;
  line-height: 32px;
  text-align: center;
}
.serp-card-rank-position {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  margin: 5px 5px 2px 4px;
}



@media (max-width:  370px) {
  .ranking-checker-col {
    padding: 0px;
  }
  .rank-holder {
    margin-left: 5px;
  }
  .position-title {
      margin-right: 0px;
  }
  .checker-col {
    padding: 0px !important;
  }
}
