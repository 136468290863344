.header-title-text {
  font-family: "proxima-nova","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 600;
  line-height: 1.2em;
  font-family: itc-avant-garde-gothic-pro;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0em;
  text-transform: none;
  line-height: 1.4em;
  font-size: 2rem;
}

.header-title-text:hover {
  cursor: pointer;
}

h1,h2,h3,h4 {
  font-family: "proxima-nova","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 600;
  line-height: 1.2em;
  font-family: itc-avant-garde-gothic-pro;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0em;
  text-transform: none;
  line-height: 1.4em;
  font-size: 2rem;
  margin: 2rem 0;
}

h1 {
  line-height: 1.1368
}

h2 {
  line-height: 1.2208
}

h3 {
  line-height: 1.2992
}

h4 {
  line-height: 1.3664
}

h5,p,label,a {
  font-family: "proxima-nova","Helvetica Neue",Helvetica,Arial,sans-serif;
  letter-spacing: 0em;
  font-family: itc-avant-garde-gothic-pro;
  font-weight: 400;
  font-style: normal;
  letter-spacing: .02em;
  text-transform: none;
  font-size: 1rem;
  line-height: 1.8;
  -webkit-font-smoothing: antialiased;
}

h6 {
  font-family: "proxima-nova","Helvetica Neue",Helvetica,Arial,sans-serif;
  letter-spacing: 0em;
  font-family: itc-avant-garde-gothic-pro;
  font-weight: 400;
  font-style: normal;
  letter-spacing: .02em;
  text-transform: none;
  font-size: .75rem;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
}

a {
  color: $primary;
}

u {
  cursor: pointer;
}

p {
  &.error {
    color: $error-color;
  }
}

.body-small {
  font-size: .75rem;
  @media(max-width: 454px) {
    font-size: .65rem;
  }
}

@media screen and (min-width: 0) and (max-width:calc(1512px - 1px)) and (orientation:landscape) {
  .header-title-text {
      font-size:calc(1.2vw + 1rem)
  }
}

@media screen and (min-width: 0) and (max-width:calc(1512px - 1px)) and (orientation:portrait) {
  .header-title-text {
      font-size:calc(1.2vh + 1rem)
  }
}

@media screen and (min-width: 1512px) {
  .header-title-text {
      font-size:2rem
  }
}

@media screen and (min-width: 0) and (max-width:calc(1512px - 1px)) and (orientation:landscape) {
  h1 {
      font-size:calc(5.64vw + 1rem)
  }
}

@media screen and (min-width: 0) and (max-width:calc(1512px - 1px)) and (orientation:portrait) {
  h1 {
      font-size:calc(5.64vh + 1rem)
  }
}

@media screen and (min-width: 1512px) {
  h1 {
      font-size:5.7rem
  }
}

@media screen and (min-width: 0) and (max-width:calc(1512px - 1px)) and (orientation:landscape) {
  h2 {
      font-size:calc(3.84vw + 1rem)
  }
}

@media screen and (min-width: 0) and (max-width:calc(1512px - 1px)) and (orientation:portrait) {
  h2 {
      font-size:calc(3.84vh + 1rem)
  }
}

@media screen and (min-width: 1512px) {
  h2 {
      font-size:4.2rem
  }
}

@media screen and (min-width: 0) and (max-width:calc(1512px - 1px)) and (orientation:landscape) {
  h3 {
      font-size:calc(2.16vw + 1rem)
  }
}

@media screen and (min-width: 0) and (max-width:calc(1512px - 1px)) and (orientation:portrait) {
  h3 {
      font-size:calc(2.16vh + 1rem)
  }
}

@media screen and (min-width: 1512px) {
  h3 {
      font-size:2.8rem
  }
}

@media screen and (min-width: 0) and (max-width:calc(1512px - 1px)) and (orientation:landscape) {
  h4 {
      font-size:calc(.72vw + 1rem)
  }
}

@media screen and (min-width: 0) and (max-width:calc(1512px - 1px)) and (orientation:portrait) {
  h4 {
      font-size:calc(.72vh + 1rem)
  }
}

@media screen and (min-width: 1512px) {
  h4 {
      font-size:1.6rem
  }
}

@media (max-width: 576px) {
  .nav-link {
    font-size: 8.5vmin;
  }
}
