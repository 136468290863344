.fa-file-download {

}

.download-btn {
  position: relative;
  top: 8px;
  left: 10px;
  margin-right: 9px;
}

.export-text {
  text-transform: uppercase;
  margin-top: 0px;
  position: relative;
  top: 14px;
  left: 7px;
  margin-bottom: 0px;
  font-size: .7rem;
}

.export-lock {
  margin-top: 14px;
  margin-left: 15px;
}

#google-serp-export {
  height: 40px !important;
  width: 90px !important;
  padding: 0px !important;
  position: relative;
}

.serp-export {
  color: #4f4f4f !important;
  box-shadow:  0px 0px 0px 0px !important;
}

.serp-export:hover {
  color: #4f4f4f !important;
  box-shadow:  0px 0px 0px 0px !important;
}

#google-serp-export * {
    pointer-events: none;
}
