.back-to-top {
  position: fixed;
  right: 30px;
  bottom: 70px;
  background: $background1;
  box-shadow: 0px 0px 7px 0px gray;
  padding: 8px 14px 5px 14px;
  border: 1px solid #000;
  border-radius: 5px;
  font-size: 1.5rem;
  cursor: pointer;
}

.back-to-top-arrow {
  padding: 0rem;
  margin-top: 5px;
}
