.nav-tabs .nav-link.active {
  color: $primary;
  border-color: $primary;
  background-color: $background1;
  font-family: "proxima-nova","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-family: itc-avant-garde-gothic-pro;
}

.nav-tabs .nav-link:hover {
  background-color: $background1;
}

.nav-tabs .nav-link {
  text-transform: none;
  font-size: 16px;
  border-width: 0 0 4px 0;
}
.navbar-collapse {
  width: 80% !important;
}
