.sign-in-create-account-modal {
  background-color: $background1;
}

.google-sign-in {
  display: flex;
  align-items: center;
  justify-content: center;
}

hr.signin-hr { border-top: 1px solid black; }
