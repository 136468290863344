.breadcrumb {
  background-color: transparent;
  padding-left: 0;
}

.breadcrumb > .breadcrumb-item {
  font-family: "proxima-nova","Helvetica Neue",Helvetica,Arial,sans-serif;
  letter-spacing: 0em;
  font-family: itc-avant-garde-gothic-pro;
  font-weight: 400;
  font-style: normal;
  letter-spacing: .02em;
  text-transform: none;
  line-height: 1.8em;
  font-size: 1rem;
  line-height: 1.8;
  -webkit-font-smoothing: antialiased;
}