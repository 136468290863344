.testing-div {
  position: absolute;
  left: 0px;
  bottom: 200px;
  z-index: 0;
  background-color: #f2ddf9;
  border-radius: 5px;
  max-width: 320px;
  max-height: 400px;
  z-index: 1;
  overflow-x: hidden;
  overflow-y: auto;
  text-align:justify;
}

.close-panel {
  float: right;
  position: relative;
  left:25px;
  top: 8px;
  padding: 8px 15px 8px 15px;

}

.show-panel {
  position: absolute;
  left: 0px;
  bottom: 300px;
  z-index: 1;
  padding: 8px 15px 8px 15px;
}

.prompt-list {
  list-style: none;
  padding-left: 0px;
  line-height: 1;
}

.testing-div-outline {
  border-width: 2px;
  border-style: solid;
  box-shadow: none;
  padding-top: 8px;
  padding-bottom: 6px;
  padding-left: 22px;
  padding-right: 22px;
}
.clear-results-btn {
  float: right;
  padding: 8px 15px 8px 15px;
}
