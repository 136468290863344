.yt-video {
  width: 560px !important;
  height: 315px;
 }

 .anchor-btn-holder {
   margin-bottom: 8px !important;
 }

.anchor-btn {
  width: 90% !important;
  height: 100% !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  letter-spacing: 1px !important;
}

 .serp-pg-img {
   -ms-interpolation-mode: nearest-neighbor;
    image-rendering: crisp-edges;
 }

 .serp-settings-img {
   width: 40% !important;
 }

 ol.serp-pg-schema-type-list li {
  margin-bottom: 5px;
 }
