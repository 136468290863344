#metricsDropDown {
  max-width: 500px;
}

.site-metric-title {
  margin-bottom: 1.2rem;
  margin-top: .5rem !important;
  text-decoration: underline;
  text-underline-position: under;
}

.metrics-title { display: block; }

.metric-dropdown-btn:hover { border: 2px solid #000 !important; }

.metric-dropdown-btn {
  height: auto;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  border: 2px solid #000 !important;
  border-radius: 10px;
  text-align: left !important;
  text-transform: none !important;
  font-size: 16px !important;
}

.metric-dropdown-options {
  height: auto;
  width: 100%;
  transform: translate3d(0px, 68px, 0px)!important;
}

.metric-field-container {
  .metric-field-flex-container {
    display: flex;
    border: 1px solid $psi-grey;
    border-radius: 10px;
    padding: 10px;
    align-items: center;
  }
  .core-web-vitals {
    color: $grey;
    display: inline-block;
    padding-right: .5rem;
    margin-bottom: 0;
  }
}
.metrics-load-psi-link {
  text-align: right;
}
.metric-uppercase {
  text-transform: uppercase;
}
.spinner-border {
  &.metric-spinner {
    width: 1rem;
    height: 1rem;
  }
}

.metric-label-type {
  color: $primary
}

.filmstrip-container {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  padding: 0;
  li {
    display: inline;
    opacity: 0;
    animation: fadeIn 0.9s 1;
    animation-fill-mode: forwards;
    img {
      padding: 0 5px;
    }
  }
  li:nth-child(10n+1) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  li:nth-child(10n+2) {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  li:nth-child(10n+3) {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  li:nth-child(10n+4) {
    -webkit-animation-delay: 0.8;
    animation-delay: 0.8s;
  }
  li:nth-child(10n+5) {
    -webkit-animation-delay: 1.0s;
    animation-delay: 1.0s;
  }
  li:nth-child(10n+6) {
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
  }
  li:nth-child(10n+7) {
    -webkit-animation-delay: 1.4s;
    animation-delay: 1.4s;
  }
  li:nth-child(10n+8) {
    -webkit-animation-delay: 1.6s;
    animation-delay: 1.6s;
  }
  li:nth-child(10n+9) {
    -webkit-animation-delay: 1.8s;
    animation-delay: 1.8s;
  }
  li:nth-child(10n+10) {
    -webkit-animation-delay: 2.0s;
    animation-delay: 2.0s;
  }
}

.psi-card-filmstrip-and-link-container {
  display: flex;
  flex-direction: row;
  @media (min-width: 576px) {
    .flimstrip-preview {
      // padding-right: 1rem;
      width: 90%;
    }
  }
}

@media (min-width: 576px) {
  .psi-results-container {
    margin-top: 3rem;
  }

}
@media (min-width: 576px) and (max-width: 768px) {
  .core-web-vitals {
      text-align: center;
      line-height: 1.5 !important;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0.0;
  }
  100% {
    opacity: 1.0;
  }
}

// small
@media (max-width: 575.98px) {
  .metric-tabs {
    justify-content: center;
  }
  .lab-content-flex {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    .btn-radio-group {
      width: 50%;
      display: flex;
      flex-direction: column;
    }
  }
  .metric-field-container {
    .metric-field-flex-container {
      flex-direction: column;
    }
    .metirc-field-fcp-flex-container {
      display: flex;
      justify-content: center;
      margin-top: .5rem;
    }
    .core-web-vitals {
      padding-right: 0;
      // margin-bottom: .5rem;
    }
  }
  .metrics-load-psi-link {
    padding-top: 1rem;
  }
  .metrics-no-data {
    text-align: center;
  }
}
// med and up
@media (min-width: 576px) {
  .metric-field-container {
    display: flex;
    .metric-field-flex-container {
      flex-direction: row;
      display: inline-flex;
    }
    .metirc-field-fcp-flex-container {
      display: inline-flex;
      align-items: center;
      margin-left: .5rem;
    }
  }
}
