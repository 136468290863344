@import
  "./scss/styleguide/palette",
  "./scss/styleguide/typography",
  "./scss/styleguide/breadcrumb",
  "./scss/styleguide/buttons",
  "./scss/styleguide/card",
	"./scss/styleguide/progress",
	"./scss/styleguide/forms",
  "./scss/styleguide/psi-score-scale",
  "./scss/styleguide/seo",
  "./scss/styleguide/account-gate-modal",
  "./scss/styleguide/sign-in-create-account-modal",
  "./scss/styleguide/header",
  "./scss/styleguide/tabs",
  "./scss/styleguide/metrics",
  "./scss/styleguide/pricing",
  "./scss/styleguide/payment",
  "./scss/styleguide/alert",
  "./scss/styleguide/speed-test",
  "./scss/styleguide/image-skeleton",
  "./scss/styleguide/avg-stats-card",
  "./scss/styleguide/content-schema-tabs",
  "./scss/styleguide/serp-rank-checker-modal",
  "./scss/styleguide/keyword",
  "./scss/styleguide/side-panel",
  "./scss/styleguide/back-to-top",
  "./scss/styleguide/export-data",
  "./scss/styleguide/serp-rank-checker",
  "./scss/styleguide/metric-badges",
  "./scss/styleguide/serp-psi-page-copy",
  "./scss/styleguide/keyword-settings-modal";

/* Custom */
html, body {
  height: 100%;
}

#sandbox-tools{
  height: 100%;
}

.serp-psi-page-bkg {
  min-height: 100%;
  background-color: $background2;
}

.page-content-padding {
  padding-top: 5rem;
}

.page-background1 {
  min-height: 100%;
  background-color: $background1;
}

.background1 {
  background-color: $background1;
}

body {
  font-family: "proxima-nova","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-family: itc-avant-garde-gothic-pro;
  color: $primary;
}

.hr {
  background-color: rgba(128, 128, 128, .5);
  height: 2px !important;
  margin-left: 20px;
  margin-right: 20px;
}


.algorithm-tooltip {
  animation: fadeIn 0.3s ease-in-out !important;
-webkit-animation: fadeIn 0.3s ease-in-out !important;
-moz-animation: fadeIn 0.3s ease-in-out !important;
-o-animation: fadeIn 0.3s ease-in-out !important;
-ms-animation: fadeIn 0.3s ease-in-out !important;
  background-color: #6d6d6d !important;
  padding: 6px 16px;
  border-radius: 4px;
  font-family: var(--mdb-font-roboto) !important;
  color: #fff !important;
  font-size: 14px;
  letter-spacing: normal !important;
}

.custom-tooltip {
  text-align: center;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.drawer-img-container {
  // overflow: hidden !important;
  margin-bottom: 10px !important;
}

.keyword-drawer-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width:fit-content;
  max-width: 400px;
  max-height: 400px;

}
.offcanvas-end {
  width: 450px !important;
}
.chart-yr{
  color: #666;
  font-size: .8rem;
}

.retry                     { pointer-events: none; }
.none                      { display: none !important; }
a:hover                    { color: inherit; }
.arrow-none                { display: none; }
.bg-padding                { padding-left: 1rem; }
.more-data                 { font-weight: bold; }
.tab-pane                  { min-height: 100px; }
.type-writer               { display: inline-flex;}
.block-title               { display: block; }
.serp-analysis             { margin-bottom: 0rem; }
.header-link:hover         { color: $primary; }
.compare-site-speed        { margin-top: 0rem; }
.toggle-switch-container   { padding-right: 0rem !important; }
.remaining-searches        { text-align: right; }
.wst-remaining-searches    { padding: 0px !important; }
.free-usage-message        { font-size: 22px !important; }
.test-metric-tabs          { max-width: 500px; }
.dropdown-item.active      { border-radius: 5px; }
.clear-append-btn          { font-size: 12px; }

.keyword-progress-container { 
  // padding-left: 90px; 
  // padding-right: 90px;
  position: relative;
  display: flex !important;
  justify-content: center !important;
}
.kp-bar {
  position: absolute;
  width: 50%;
  left: 25%;
}
.total-errors-btn { //mixlab
  cursor: pointer;
  &:hover {
  color: #6188f5;
  }
}

.drawer-btn {
  width: 100% !important;
  margin-bottom: 10px;
}

.drawer-header {
  padding: 1rem;
}

@media(max-width: 1200px) {
  .keyword-panel {
    margin-left: 100px;
    margin-right: 100px;
  }
}

@media(min-width: 1200px) {
  .wst-tab-content {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}

@media(max-width: 1000px)
  {
  .tab-pane {
    min-height: 130px;
  }
  .warning-container{
    left: 20%;
    width: 60% !important;
  }

}

@media(max-width: 992px){
  .serp-pg-img {
    width: 100%;
  }
  .serp-settings-img {
    width: 60% !important;
  }
}

@media(max-width: 784px)
  {
  .keyword-panel {
    margin-left: 50px;
    margin-right: 50px;
  }
  .yt-video {
    width: 100% !important;
    height: 285px !important;
  }
  .serp-settings-img {
    width: 80% !important;
  }
  .warning-container{
    left: 15%;
    width: 70% !important;
  }
    .warning-container-fixed{
    left: 15%;
    width: 70% !important;
  }
}

@media(max-width: 576px)
  {
    .keyword-progress-container{
      display: block !important;
    }
    .serp-psi-search-flag-copy {
      text-align: center;
      padding-top: .75rem;
    }
  .tab-pane {
    min-height: 250px;
  }
  .type-writer{
    display: block;
  }
  .bg-padding {
    padding-left: 0rem;
  }
  .remaining-searches {
    text-align: center;
  }

  .keyword-panel {
    margin-left: 0px;
    margin-right: 0px;
  }
  .keyword-container {
    padding: 5px;
  }
  .tool-header {
    font-size: 1.8rem;
  }

  .kw-settings { font-size: .6rem !important; }

  .yt-video {
    width: 100% !important;
  }

  .anchor-btn-holder {
    margin-bottom: 15px !important;
  }

  .google-serp-export {
    padding-right: 12px !important;
  }
  .algo-icon {
    font-size: .9rem !important;
  }
}

@media (max-width: 454px ) {
  .d-flex {
    font-size: .8rem;
  }
  .progress {
    width: 42%;
  }
  .keyword-progress {
    width: 100% !important;
  }

  .keyword-value {
    font-size: .9rem
  }
  .msv-value {
    font-size: .9rem;
  }
  .tool-header {
    font-size: 1.7rem;
  }

  .serp-settings-img {
    width: 100% !important;
  }

  .google-serp-export {
    padding-right: 4px !important;
  }
  .append-header {
    font-size: .8rem !important;
  }
  .msv-text {
    font-size: .75rem !important;
  }

  .submit-append {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .warning-container {
    left: 5%;
    width: 90% !important;
  }
  
  .warning-container-fixed {
    left: 15%;
    width: 70% !important;
  }

}
