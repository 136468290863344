.card {
	border: 0px;
	&.google-says {
		@media (max-width: 576px) {
			img {
				width: 50px;
			}
		}
	}
}

.card-body {
  background-color: $background1;
	color: $primary;
	a:hover {
		cursor: pointer;
		text-decoration: underline;
	}
	&.clickable {
		cursor: pointer;
	}
}

.error {
	color: $psi-fail;
}

.google-says-card {
	cursor: pointer;
}