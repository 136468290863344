.animated-img-holder { width: 120px; }

.skeleton-holder {
  box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
  background-color: #F7F7F7 !important;
  width: 90%;
}

.animated-filmstrip {
  display: block;
  position: relative;
  overflow: hidden;

  &:before {
      content: '';
      display: block;
      position: absolute;
      left: -150px;
      top: 0;
      height: 100%;
      width: 150px;
      background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 80%);
      animation: load 1s cubic-bezier(0.4, 0.0, 0.4, 1) infinite;
      z-index: 1;
      @keyframes load {
          from {
              left: -100px;
          }
          to   {
              left: 100px;
          }
      }
    }
}

.rect-animated-img-holder {
  width: 50px;
  margin-bottom: .35rem;
 }

.rectangle-skeleton-holder {
  box-shadow: 0 2px 10px 0 rgba(33, 33, 33, 0.15);
  background-color: #F7F7F7 !important;
  width: 90%;
}

.metrics-rectangle-animated {
  display: block;
  position: relative;
  overflow: hidden;
  height: 12px !important;

  &:before {
      content: '';
      display: block;
      position: absolute;
      left: -150px;
      top: 0px;
      height: 12px !important;
      width: 150px;
      background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 80%);
      animation: metrics-rectangle-animated 1s cubic-bezier(0.4, 0.0, 0.4, 1) infinite;
      z-index: 1;
      @keyframes metrics-rectangle-animated {
          from {
              left: -100px;
          }
          to   {
              left: 60px;
          }
      }
    }

}

.schema-animated-img-holder {
  width: 100px;
}

.schema-rectangle-animated {
  display: block;
  position: relative;
  overflow: hidden;
  height: 13px !important;

  &:before {
      content: '';
      display: block;
      position: absolute;
      left: -150px;
      top: 0px;
      height: 13px !important;
      width: 150px;
      background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 80%);
      animation: schema-rectangle-animated 1s cubic-bezier(0.4, 0.0, 0.4, 1) infinite;
      z-index: 1;
      @keyframes schema-rectangle-animated {
          from {
              left: -100px;
          }
          to   {
              left: 60px;
          }
      }
    }

}

//drawer animations
.drawer-animated-img-holder {
  width: 100%;
}

.animated-drawer-skeleton {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;

  &:before {
      content: '';
      display: block;
      position: absolute;
      left: -150px;
      top: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 80%);
      animation: load 2s cubic-bezier(0.4, 0.0, 0.4, 1) infinite;
      z-index: 1;
      @keyframes load {
          from {
              left: -300px;
          }
          to   {
              left: 300px;
          }
      }
    }
}

.chart-animated-img-holder {
  width: 100%;
  min-height: 200px !important;
  height: 220px;
  position: relative;
  margin-bottom: 15px;
}

.chart-animated-skeleton {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;

  &:before {
      content: '';
      display: block;
      position: absolute;
      left: -150px;
      top: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 80%);
      animation: load 2s cubic-bezier(0.4, 0.0, 0.4, 1) infinite;
      z-index: 1;
      @keyframes load {
          from {
              left: -300px;
          }
          to   {
              left: 300px;
          }
      }
    }
}

