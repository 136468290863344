.algorithm-title {
  font-size: .8rem !important;
}

.algo-tooltip-holder {
  position: relative;
}

.algorithm-tooltip-inner {
    position: absolute;
    z-index: 9999;
    left: -70px;
    white-space: pre-line !important;
    max-width: 300px !important;
    width: 255px;
    text-align: center !important;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    @media(max-width: 992px) {
      // right: 0px !important;
    }
    @media(max-width: 766px) {
      // left: 25% !important;
    }
}

.alg-inner {
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    font-size: 0.875rem;
}

.algorithm-selected {
  color: white !important;
  background: #262626 !important;
}

.algo-icon {
  font-size: 1rem !important;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 5px !important;
}

.algo-icon-holder {
  position: relative;

}
