.alert-container {
  position: fixed;
  width: 100%;
  left: 0;
  top: 80px;
  z-index: 100;
  &.fade-in {
    opacity: 1;
    animation: fadeIn ease .5s;
    -webkit-animation: fadeIn ease .5s;
    -moz-animation: fadeIn ease .5s;
    -o-animation: fadeIn ease .5s;
    -ms-animation: fadeIn ease .5s;
  }
  .alert-onboarding {
    color: $primary;
    .alert-close {
      color: $grey;
    }
    .alert-copy {
      width: 100%;
      color: $primary !important;
    }
    @media (max-width: 576px) {
      .alert-copy {
        width: 90%;
      }
    }
  }
  
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
  }