// Reboot

body {
  font-family: $font-family-base;
  line-height: $line-height-base;
  color: $body-color;
}

// Links

a {
  text-decoration: none;
}

button:focus {
  outline: 0;
}