.metric-badge-holder {
  text-align: center;
  @media(max-width: 576px) {
    margin-bottom: .5rem;
    margin-left: 0px;
  }
}

.metric-badge {
  font-size: 11px;
  @media(max-width: 576px) {
    font-size: 12px;
  }
}
