.psi-score-scale-container {
  display: inline-flex;
  margin: 12px auto 0 auto;
  border: 1px solid $psi-grey;
  border-radius: 20px;
  padding: 8px 8px;
  margin-bottom: 24px;
}

.psi-score-range {
  display: flex;
  align-items: center;
  margin: 0 6px;
  white-space: nowrap;
  &:before {
    content: '';
    width: 13px;
    height: 6px;
    border-radius: 10px;
    display: block;
    margin-right: 10px;
  }
}

.psi-score-scale-range-fail:before {
  background-color: $psi-fail;
}

.psi-score-scale-range-average:before {
  background-color: $psi-average;
}

.psi-score-scale-range-pass:before {
  background-color: $psi-pass;
}

.psi-score-fail {
  color: $psi-fail;
}

.psi-score-average {
  color: $psi-average;
}

.psi-score-pass {
  color: $psi-pass;
}