.progress {
  height: 4px;
  border-radius: 0;
  display: inline-flex;
  width: 40%;
  .progress-bar {
    background-color: $progressbar-color;
  }
  @media(max-width: 1200px) {
    width: 50%;
  }
}

.keyword-progress {
  height: 4px;
  border-radius: 0;
  display: inline-flex;
  width: 100% !important;
  .progress-bar {
    background-color: $progressbar-color;
  }
  @media(max-width: 1200px) {
    width: 100% !important;
  }
}
