//
// Material styles for check / radio / switch
//

.form-check {
  .form-check-input {
    margin-left: 1.79rem * -1;

    &[type='radio'] {
      margin-left: 1.85rem * -1;
    }
  }

  margin-bottom: 0;
  min-height: auto;
}

.form-check-input {
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 2px solid rgb(117, 117, 117);

  &:before {
    content: '';
    position: absolute;
    box-shadow: 0px 0px 0px 13px transparent;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    background-color: transparent;
    opacity: 0;
    pointer-events: none;
    transform: scale(0);
  }

  &:hover {
    cursor: pointer;

    &:before {
      opacity: 0.04;
      box-shadow: 0px 0px 0px 13px rgba(0, 0, 0, 0.6);
    }
  }

  &:focus {
    box-shadow: none;
    border-color: rgb(117, 117, 117);
    transition: border-color 0.2s;

    &:before {
      opacity: 0.12;
      box-shadow: 0px 0px 0px 13px rgba(0, 0, 0, 0.6);
      transform: scale(1);
      transition: box-shadow 0.2s, transform 0.2s;
    }
  }

  &:checked {
    border-color: $primary;

    &:before {
      opacity: 0.16;
    }

    &:after {
      content: '';
      position: absolute;
    }

    &:focus {
      border-color: $primary;

      &:before {
        box-shadow: 0px 0px 0px 13px $primary;
        transform: scale(1);
        transition: box-shadow 0.2s, transform 0.2s;
      }
    }
  }

  &:indeterminate {
    &:focus {
      &:before {
        box-shadow: 0px 0px 0px 13px $primary;
      }
    }
  }

  &[type='checkbox'] {
    border-radius: 2px;

    &:focus {
      &:after {
        content: '';
        position: absolute;
        width: 14px;
        height: 14px;
        z-index: 1;
        display: block;
        border-radius: 0;
        background-color: #fff;
      }
    }

    &:checked {
      background-image: none;
      background-color: $primary;

      &:after {
        display: block;
        transform: rotate(45deg);
        border-width: 2px;
        border-color: #fff;
        width: 6px;
        height: 13px;
        border-style: solid;
        border-top: 0;
        border-left: 0;
        margin-left: 4px;
        margin-top: -1px;
        background-color: transparent;
      }

      &:focus {
        background-color: $primary;
      }
    }

    &:checked:indeterminate,
    &:indeterminate {
      background-image: none;
      background-color: $primary;

      &:after {
        display: block;
        transform: rotate(90deg);
        border-width: 2px;
        border-color: #fff;
        border-width: 2px;
        width: 2px;
        height: 14px;
        border-style: solid;
        border-top: 0;
        border-left: 0;
        margin-left: 6px;
        margin-top: 0;
      }

      &:focus {
        background-color: $primary;
      }
    }
  }

  &[type='radio'] {
    border-radius: 50%;
    width: 20px;
    height: 20px;

    &:before {
      width: 16px;
      height: 16px;
    }

    &:after {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      z-index: 1;
      display: block;
      border-radius: 50%;
      background-color: #fff;
    }

    &:checked {
      background-image: none;
      background-color: #fff;

      &:after {
        border-radius: 50%;
        width: 10px;
        height: 10px;
        border-color: $primary;
        background-color: $primary;
        margin-top: 3px;
        margin-left: 3px;
        transition: border-color;
      }

      &:focus {
        background-color: #fff;
      }
    }
  }
}

.form-check-label {
  &:hover {
    cursor: pointer;
  }
}

.form-switch {
  padding-left: 1.85rem;
  .form-check-input {
    background-image: none;
    border-width: 0px;
    border-radius: 7px;
    width: 36px;
    height: 14px;
    background-color: rgba(0, 0, 0, 0.38);
    margin-top: 0.35rem;
    margin-right: 0.7rem;

    &:after {
      content: '';
      position: absolute;
      border: none;
      z-index: 2;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      background-color: #fff;
      margin-top: -3px;
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
      transition: background-color 0.2s, transform 0.2s;
    }

    &:focus {
      background-image: none;

      &:before {
        box-shadow: 3px -1px 0px 13px rgba(0, 0, 0, 0.6);
        transform: scale(1);
        transition: box-shadow 0.2s, transform 0.2s;
      }
    }

    &:checked {
      background-image: none;

      &:focus {
        background-image: none;

        &:before {
          margin-left: 17px;
          box-shadow: 3px -1px 0px 13px $primary;
          transform: scale(1);
          transition: box-shadow 0.2s, transform 0.2s;
        }
      }

      &[type='checkbox'] {
        background-image: none;

        &:after {
          content: '';
          position: absolute;
          border: none;
          z-index: 2;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          background-color: $primary;
          margin-top: -3px;
          margin-left: 17px;
          box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
            0 1px 5px 0 rgba(0, 0, 0, 0.12);
          transition: background-color 0.2s, transform 0.2s;
        }
      }
    }
  }
}
