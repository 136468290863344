// Navbar

.navbar {
  box-shadow: $box-shadow-3;
  padding-top: 0.5625rem;
}

.navbar-toggler {
  border: 0;

  &:focus {
    box-shadow: none;
  }
}

.navbar-dark .navbar-toggler,
.navbar-light .navbar-toggler {
  border: 0;
}

.navbar-brand {
  display: flex;
  align-items: center;
  img {
    margin-right: 0.25rem;
  }
}

.navbar-nav .dropdown-menu {
  position: absolute;
}
