.dropdown-menu {
  color: #212529;
  margin: 0.125rem 0 0;
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
  box-shadow: $box-shadow-2;
  font-size: 0.875rem;

  > li {
    border-radius: 0;
    &:first-child {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      .dropdown-item {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:not(:first-child):not(:last-child) {
      .dropdown-item {
        border-radius: 0;
      }
    }

    &:last-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      .dropdown-item {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
    }
  }
}

.dropdown-item {
  padding: 0.5rem 1rem;
  color: #212529;
  border-radius: 0;

  &:hover,
  &:focus {
    color: #16181b;
    background-color: #eee;
  }

  &.active,
  &:active {
    color: #16181b;
    background-color: #eee;
  }
}

.hidden-arrow {
  &.dropdown-toggle:after {
    display: none;
  }
}

.dropdown-menu {
  &.animation {
    display: block;
    /* Speed up animations */
    animation-duration: $dropdown-menu-animated-animation-duration;
    animation-timing-function: $dropdown-menu-animated-animation-timing-function;
  }
}

.animation {
  animation-duration: 1s;
  animation-fill-mode: both;
  padding: auto;
}

@media (prefers-reduced-motion) {
  .animation {
    transition: none !important;
    animation: unset !important;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation-name: fade-in;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fade-out {
  animation-name: fade-out;
}
