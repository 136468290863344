//
// Base styles
//

.input-group {
  > .form-control {
    min-height: calc(1.5em + 0.33rem + 2px);
    padding-top: 0.27rem;
    padding-bottom: 0.27rem;
    transition: all 0.2s linear;

    &:focus {
      transition: all 0.2s linear;
      border-color: $primary;
      outline: 0;
      box-shadow: inset 0 0 0 1px $primary;
    }
  }
}

.input-group-text {
  background-color: transparent;
  padding-top: 0.27rem;
  padding-bottom: 0.27rem;
}

.input-group-lg {
  > .form-control {
    height: calc(2.315em + 0.33rem + 2px);
    font-size: 1rem;
    padding-top: 0.33rem;
    padding-bottom: 0.33rem;
  }

  .input-group-text {
    font-size: 1rem;
  }
}

.input-group-sm {
  > .form-control {
    height: calc(1.7em + 0.33rem + 2px);
    font-size: 0.775rem;
    padding-top: 0.33rem;
    padding-bottom: 0.33rem;
  }

  .input-group-text {
    font-size: 0.775rem;
    line-height: 1.5;
  }
}

.input-group {
  &.form-outline {
    .input-group-text {
      border-left: 0;
    }
    input + .input-group-text {
      border: 0;
      border-left: 1px solid #bdbdbd;
    }
  }
}

.input-group > [class*='btn-outline-'] + [class*='btn-outline-'] {
  border-left: 0;
}
