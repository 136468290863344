//
// Images
//
.bg-image {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
}

.mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-attachment: fixed;
}

.hover-overlay {
  .mask {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    &:hover {
      opacity: 1;
    }
  }
}

.hover-zoom {
  img,
  video {
    transition: all 0.3s linear;
  }
  &:hover {
    img,
    video {
      transform: scale(1.1);
    }
  }
}

.hover-shadow {
  box-shadow: none;
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: all 0.3s ease-in-out;
  }
}
