
.meta-content   { font-size: 13px; }

.meta-content-data {
  padding-left: .5rem;
  @media (max-width: 454px) {
    padding-left: 0rem;
  }
}

.schema-link { float: left }

.schema-types { min-height: 70px; }

ul.meta-list {
  list-style: none;
  padding-left: 0px;
}
ul.tech-list {
  list-style: none;
  padding-left: 0px;
}

.meta-list-item {
  margin-bottom: 3px;
}

.tech-item-holder { margin: 5px; }

.tech-list-item { position: relative; }

.tech-list-title { padding: 12px 8px 8px 0px; }

.badge {
  font-size: 16px;
  font-weight: 400 !important;
  padding: .75em .75em .5em .75em !important;
}
.row-pos {
  position: relative;
  z-index: 0 !important;
}

.tool-tip-urls {
  position: absolute;
  transform: translate(-20%, -20%) !important;
  top: -100%;
  color: #fff;
  z-index: 1070 !important;
  display: block;
  padding: 6px 8px;
  background-color: #6d6d6d !important;
  border-radius: 4px !important;
  text-shadow: none;
  text-transform: none;
  font-size: 12px;
  font-family: var(--mdb-font-roboto);
  font-weight: 400;
  line-height: 1.6;
  font-style: normal;
  word-wrap: break-word;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
}


.content-title {
 color: #000;
 font-weight: bold;
}

.list-wrap {
  display: flex;
  flex-wrap: wrap;
  @media(max-width: 584px) {
    font-size: .9rem !important;
  }
}
.canonical-url {
  font-size: 1rem !important;
  @media(max-width: 584px) {
    font-size: .9rem !important;
  }
}
