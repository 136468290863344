// Toasts

.toast {
  background-color: #fff;
  border: 0;
  box-shadow: $box-shadow-2;
}

.toast-header {
  background-color: #fff;
}
