.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.stripe-element-container {
  padding: 0;
  border: 1px solid $primary;
  background-color: $white;
  border-radius: 4px;
  &.border-error {
    border-color: $error-color;
  }
  +label.error {
    margin-bottom: 10px;
  }
}

.stripe-element {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  .FormRowInput {
    font-size: 16px;
    width: 100%;
    padding: 11px 15px 11px 0;
    color: #fff;
    background-color: transparent;
    animation: 1ms void-animation-out;
  }
}

.payment-form-group {
  will-change: opacity, transform;
  padding: 0;
  border: 1px solid $primary;
  background-color: $white;
  border-radius: 4px;
  &.border-error {
    border-color: $error-color;
  }
  +label.error {
    margin-bottom: 10px;
  }
}

.payment-form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  padding: 11px 15px 11px 0;
  .payment-form-row-input {
    width: 100%;
    background-color: transparent;
    animation: 1ms void-animation-out;
    border-style: none;
    color: $primary;
    font-family: "proxima-nova","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
    line-height: 1.2em;
    height: 1.2em;
    outline: none;
    box-shadow: none;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    ::placeholder {
      color: #9e9e9e;
      opacity: .5; /* Firefox */
    }
  }
}