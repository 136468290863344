.avg-card       { margin-top: 1rem !important; }
.avg-word-count {
  margin-bottom: 0rem;
  margin-top: .2rem;
}

ul.avg-list {
  list-style: none;
  padding-left: 0px;
  line-height: 2.0;
}

.avg-searched-term {
  margin-bottom: .1rem !important;
  margin-top: .5rem !important;
}

.schema-item {
  margin-bottom: 2.0rem;
  display: block;
}

.filter-btn {
  padding: 9px 10px 5px 10px;
  box-shadow: 0rem .125rem .25rem gray;
  border: 1px solid #000;
  border-radius: 3px;
  cursor: pointer;
  @media (max-width: 454px) {
    padding: 9px 8px 5px 8px;
  }
}

.selected-filter-btn {
  padding: 9px 10px 5px 10px;
    // padding: 5px 8px 0px 8px;
  box-shadow: 0rem .125rem .25rem gray;
  background-color: #000 !important;
  border: 1px solid $background1 !important;
  border-radius: 3px;
  cursor: pointer;
  @media (max-width: 454px) {
    padding: 9px 8px 5px 8px;
  }
}
.selected-filter-icon {
  color: $background1 !important;
}

.avg-content   {
  font-size: 13px !important;
  		@media (max-width: 454px) {
        font-size: 12px !important;
      }
}

.view-more-schema {
  margin-top: 25px !important;
  margin-bottom: 25px;
  font-size: 16px !important;
  @media (max-width: 454px) {
    font-size: 15px !important;
  }
}

.avg-scroller {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

}
.structured-data {
  margin-right: 5px;
  min-width: 200px;
  overflow: hidden;
}

.pages-left {
  margin-bottom: 25px;
  font-size: 16px !important;
  @media (max-width: 454px) {
    font-size: 15px !important;
  }
}
