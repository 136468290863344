$primary: #000;
$background1: #f2ddf9;
$background2: #F3E4D8;
$psi-pass: #0cce6b;
$psi-average: #ffa400;
$psi-fail: #ff4e42;
$psi-grey: #9e9e9e;
$progressbar-color: #6188f5;
$link-color: #1a0dab;
$error-color: #ff4e42;
$light-blue: #E6F7FF;
$grey: #666666;
$white: #fff;
$toggle-switch-blue: #1E9EBB;
