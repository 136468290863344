// Card

.card {
  border: 0;
  box-shadow: $box-shadow-3;
}

.card-header {
  background-color: rgba(255, 255, 255, 0);
}

.card-footer {
  background-color: rgba(255, 255, 255, 0);
}
