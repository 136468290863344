.btn-primary {
  color: $primary;
  background-color: $background1;
  border: 1px solid $primary;
}

@media (max-width: 767.98px) {
  .btn-primary {
		&.max-width {
			width: 100%;
		}
  }
}

.btn-primary:hover {
  color: $primary;
  background-color: $background1;
  border: 1px solid $primary;
  opacity: .8;
}

.btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle {
  color: $primary;
  background-color: $background1;
  border: 1px solid $primary;
}

.btn-primary.focus, .btn-primary:focus, .btn-primary:hover {
  color: $primary;
  background-color: $background1;
  border: 1px solid $primary;
}

.btn-outline-primary {
  color: $primary;
  background-color: $background1;
  border-color: $primary;
}

.btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show, .btn-outline-primary.focus, .btn-outline-primary:active, .btn-outline-primary:focus {
  background-color: $background1;
  border-color: $primary;
  color: $primary;
}

.btn-outline-primary:hover {
  background-color: $background1;
  border-color: $primary;
  color: $primary;
  opacity: .8;
}

.btn-primary:disabled, .btn-primary.disabled {
	color: $primary;
	background-color: $background1;
	border: 1px solid $primary;
}

.btn-link {
  color: $primary;
  padding: .5rem;
  font-family: "proxima-nova","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-family: itc-avant-garde-gothic-pro;
  text-transform: none;
  font-size: 1rem;
}

.btn-link.focus, .btn-link:focus, .btn-link:hover {
  background-color: transparent;
}

.hamburger {
  display: inline-block;
  cursor: pointer;
  .bar1, .bar3 {
    width: 35px;
    height: 2px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
    -webkit-appearance: none;
  }
  .invis {
    width: 35px;
    height: 2px;
    margin: 6px 0;
  }
  &.change .bar1 {
    transform: rotate(-45deg) translate(-7px, 5px);
  }
  &.change .bar3 {
    transform: rotate(45deg) translate(-6px, -4px);
  }
}

.btn-radio-group {
  display: inline;
}

.btn-check:checked + .btn-outline-dark {
  &.psi-score-fail {
    color: $psi-fail;
  }
  &.psi-score-average {
    color: $psi-average;
  }
  &.psi-score-pass {
    color: $psi-pass;
  }
  .metric-label-type {
    color: $white;
  }
}

.btn-check:checked + .btn-outline-dark:focus {
  box-shadow: none;
}

.btn-check:focus + .btn-outline-dark {
  box-shadow: none;
}

.btn-outline-dark:hover {
  background-color: rgba($primary, 0.5);
}

.btn.disabled {
  pointer-events: auto;
}

